import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import StyledDropzone from '../StyledDropzone';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface FileUploaderProps {
  service: any;
  entityId: number;
  attachmentName: string;
  filename: string | null;
  onUploadSuccess?: any;
}

function FileUploader(props: FileUploaderProps) {
  const { t } = useTranslation();
  const { service, entityId, attachmentName, filename, onUploadSuccess } = props;

  const [openDropzone, setOpenDropzone] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [tmpCanDownload, setTmpCanDownload] = useState<boolean>(false);
  const [attachmentFilename, setAttachmentFilename] = useState(filename);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTmpCanDownload(typeof filename === 'string');
  }, []);

  const attachFile = () => {
    if (entityId) {
      setOpenDropzone(true);
    }
  };

  const downloadAttachment = () => {
    if (entityId) {
      service.downloadAttachment(entityId, attachmentName);
    }
  };

  const deleteAttachment = () => {
    if (entityId) {
      service.deleteAttachment(entityId, attachmentName);
      setTmpCanDownload(false);
    }
  };

  const handleUpload = () => {
    if (fileToUpload && entityId) {
      service.attachFiles(fileToUpload, attachmentName, entityId).then(() => {
        if (onUploadSuccess) onUploadSuccess();
        setTmpCanDownload(true);
        enqueueSnackbar('Attachment uploaded.', { variant: 'success' });
        setOpenDropzone(false);
      });
    }
  };

  const handleClose = () => {
    setFileToUpload(null);
    setOpenDropzone(false);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      enqueueSnackbar('The file you are trying to upload exceeds 20 MiB.', { variant: 'error' });
      return;
    }

    const file = acceptedFiles[0];
    if (file) {
      setAttachmentFilename(file.name);
      setFileToUpload(file);
    }
  };

  return (
    <div>
      <div>
        <ButtonGroup variant="outlined" size="small">
          <Button onClick={attachFile}>
            {typeof filename === 'string' || tmpCanDownload
              ? attachmentFilename
              : t('actions.insert')}
          </Button>
          {tmpCanDownload && (
            <Button onClick={downloadAttachment} color="success">
              <DownloadIcon />
            </Button>
          )}
          {tmpCanDownload && (
            // this delete works on a FILE, confirmation is not needed
            <Button onClick={deleteAttachment} color="error">
              <DeleteIcon />
            </Button>
          )}
        </ButtonGroup>
      </div>
      <Dialog open={openDropzone}>
        <DialogTitle>{t(`attachment-modal.title`)}</DialogTitle>
        <DialogContent>
          <StyledDropzone onDrop={handleDrop} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('actions.cancel')}</Button>
          <Button onClick={handleUpload} disabled={!fileToUpload}>
            {t('actions.upload')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FileUploader;
